<template>
  <v-dialog
    v-model="isDialogVisible"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text class="pt-3">
        Veuillez patienter
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoaderDialog',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    isDialogVisible: false,
  },
}
</script>

<style scoped>

</style>
