<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
<!--        <v-img
          :src="appLogo"
          max-height="250px"
          max-width="250px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>-->
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">


            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0 mr-5">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="appLogo"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pt-16"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Mot de passe oublié? 🔒
                  </p>
                  <p class="mb-2">
                    Saisissez votre adresse électronique et nous vous enverrons les instructions pour réinitialiser votre mot de passe.
                  </p>
                </v-card-text>
                <v-alert
                  v-model="isAlertVisible"
                  color="error"
                  text
                  transition="slide-y-transition"
                >
                  {{ message }}
                </v-alert>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="form"
                    @submit.prevent="forgotPassword"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      type="email"
                      placeholder="john@example.com"
                      hide-details="auto"
                      class="mb-4"
                      :rules="[rules.password_required]"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      :disabled="isDialogVisible"
                      :loading="isDialogVisible"
                    >
                      Envoyer la requête
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Retour à la page de connexion</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <loader-dialog :is-dialog-visible="isDialogVisible" />
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { required, emailValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import store from '@/store'

export default {
  name: 'ForgotPassword',
  components: { LoaderDialog },
  setup() {
    const { router } = useRouter()
    const isPasswordVisible = ref(false)
    const email = ref('')
    const isAlertVisible = ref(false)
    const message = ref('')
    const isDialogVisible = ref(false)
    const form = ref(null)
    const rules = {
      password_required: true,
    }
    const forgotPassword = () => {
      form.value.validate()
      isAlertVisible.value = false
      isDialogVisible.value = true
      store.dispatch('user/forgotPassRequest', email.value).then(response => {
        rules.password_required = 'Email obligatoire'
        message.value = response.message
        isDialogVisible.value = false
        isAlertVisible.value = true
        router.push({ name: 'reset-password' })
      }).catch(error => {
        rules.password_required = 'Email obligatoire'
        message.value = error.response.data.message
        isAlertVisible.value = true
        isDialogVisible.value = false
      })
    }

    return {
      rules,
      isPasswordVisible,
      email,
      isDialogVisible,
      isAlertVisible,
      message,
      forgotPassword,
      form,
      required,
      emailValidator,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
